import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../../components/Navigation';



const Federec = () => {

    return (
        <div className='alinea'>
            <Navigation/>
                <div className='longLiveContent'>
                    <div className='player'>
                        <ReactPlayer url="https://vimeo.com/793311091/fc549f0147" controls/>
                        
                    </div>
                    <div className='credits'>
                        <h1>Federec</h1>
                    </div>
                </div>
                
            </div>
    );
};

export default Federec;