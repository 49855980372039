import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Lavieclaire = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/792938471/4da9a0b132" controls/></div>
                <div className='credits'>
                    <h1>La vie claire</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt  <br/>
                    <span>Agency</span> Studio Drive<br/>
                    <span>Producer</span> Marie Vivière <br/>
                    <span>Director</span> Alexandre Meunier<br/>
                    <span>Electrician</span> Alexandre Thimonier<br/>
                    <span>Photographer</span> Jean Baptiste Gautier<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Lavieclaire;