import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Taqt = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/792942105" controls/></div>
                <div className='credits'>
                    <h1>Taqt x crédit agricole</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Producer</span> Marie Vivière <br/>
                        <span>Director</span> Alexandre Meunier<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Taqt;