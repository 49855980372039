import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Cite = () => {

    return (
        <div className='cite'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/778915829" controls/></div>
                <div className='credits'>
                    <h1>Bouygues immobilier</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Alexandre Meunier<br/>
                        <span>Drone operator</span> Aurélien Baud<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Cite;