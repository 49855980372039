import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Paris = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/415899087" controls/></div>
                <div className='credits'>
                    <h1>Paris 1985 <br/> Lecomte de Brégeot</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt<br/>
                    <span>Director</span> Aurélien Baud<br/>
                    <span>Producer</span> Marie Vivière<br/>
                    <span>1st AD </span>Alexandre Meunier<br/>
                    <span>2nd AD</span> JB Gautier<br/>
                    <span>DOP</span> David Montcher<br/>
                    <span>1st AC</span> Simon Bouin<br/>
                    <span>Steadicamer</span> Luc Peiser<br/>
                    <span>Electricians</span> Thomas Leroudier - Laura Perroto<br/>
                    <span>Machiniste</span> Aloïs Ballanfat<br/>
                    <span>Chef Décoratrice</span> Angélique Monbeig<br/>
                    <span>Décorateur</span> Pierre Henri Cote<br/>
                    <span>Make Up artist</span> Alexia Boizet - Jade Rodriguez<br/>
                    <span>Styliste</span> Jordan Doucet<br/>
                    <span>Coiffeuse</span> Lucie Pfeiffer’ova<br/>
                    <span>Régisseur</span> Julien Meuterlos<br/>
                    <span>Catering</span> Antoine Gonnot<br/>
                    <span>Monteur</span> Aurélien Baud<br/>
                    <span>Étalonneur</span> Baptiste Noyé<br/>
                    <span>Motion designer</span> Lucas Brunier<br/>
                    <span>Sound designer</span> Romain Bernardini<br/>
                    <span>Graphic designer</span> Thierry Chopain<br/>
                    <span>Comédien.ne.s</span><br/>
                    Nymphea morning star Catherine Pine o’noir<br/>
                    Vazek Tomi Trauma Alex Rivière Le Chatelain<br/>
                    Getheme Dancestyle Djalim Drack Ken Trophy Matthieu Neumeyer
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Paris;