import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';

const LongLiveTheKing = () => {
    return (
        <div className='longLive'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer className="react-player"
           url="https://vimeo.com/415569552" controls /></div>
                <div className='credits'>
                    <h1>Long Live The King</h1>
                    <br/>
                    <p> 
                    <span>Starring</span> Camille Cabanis <br/>
                    <span>Voice-over</span> by Seth Young-Ruiz <br/>
                    <br/>
                    <span>Director</span> Loïc Foulon <br/>
                    <span>Production</span> Abrupt / 7ème Etage <br/>
                    <br/>
                    <span>Producers</span> Marie Vivière / Maël André <br/>
                    <span>1st AD</span> Alexandre Meunier <br/>
                    <span>Cinematographer</span> Jean Combier <br/>
                    <span>Set decorator</span> Angelique Monbeig <br/>
                    <span>Stylist</span> Julie Simoneau <br/>
                    <span>1st AC</span> Guillaume Mattana <br/>
                    <span>2nd AC</span> Sébastien Roche <br/>
                    <span>Electricians</span> Théo Charpentier / David Capsir <br/>
                    <span>Sound mixer / sound designer</span> Romain Bernardini <br/>
                    <span>Music</span> Adam Ziółkowski a.k.a Violent <br/>
                    <span>Make-up</span> Jade Rodriguez <br/>
                    <span>Location manager</span> Simon Devin <br/>
                    <span>Location assistants</span> Hugo Chapuis / Victor Gardette <br/>
                    <span>Extras manager</span> Mishket Jeumai <br/>
                    <span>Stunt coordinator</span> Didier Laval <br/>
                    <span>Editor/visual effects</span> Loïc Foulon <br/>
                    <span>3D animation</span> Arthur Vivière <br/>
                    <span>Color grading</span> Jean Combier / Eymeric Jorat / Lumières Numeriques <br/>
                    <span>Film equipment rental</span> Indie Location <br/>
                    <br/>
                    <span>Shot on Arri Alexa Mini LF </span><br/>
                    <br/>
                        <span>Background talents</span> Jules / Clément / Yanis / Mbey-Paul /<br/>
                        Matys / Karim / Morad / Paul / Léo / Noé / Valéry / Daniel /<br/>
                        Corentin / Sidjy / Camil / Geoffray / Mootaz / Kyosuke /<br/>
                        Bake / Lucky Dra / Ebou / Lx Twn / Timo Tekken / Jessim /<br/>
                        Louan / Léo / Léa / Mélany / Valentine / Yuna / s.adlover<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LongLiveTheKing;