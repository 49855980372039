import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Souslegui = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/779328770" controls/></div>
                <div className='credits'>
                    <h1>Sous le gui</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> David Capsir<br/>
                        <span>Producers</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> Julien Jauffret<br/>
                        <span>Ass Cam</span> Julien Ruphy<br/>
                        <span>Electrician</span> Rémi Santonacci<br/>
                        <span>Make-up artist</span> Sandra Hernandez<br/>
                        <span>Editor & Color Grading</span> David Capsir<br/>
                        <span>Music mix and mastering</span> Antoine Duchene <br/>

                        <span>Cast</span> Numa - David Capsir - Malina Loana-Ferrante
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Souslegui;