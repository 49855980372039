import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Madlords = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='vertical'>
                <div className='longLiveContent'>
                    <div className='player carre'><ReactPlayer  url="https://vimeo.com/779311344" controls/></div>
                    <div className='credits'>
                        <h1>Madlords</h1>
                        <br/>
                        <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Alexandre Meunier<br/>
                        <span>Photographer</span> Jean Baptiste Gautier<br/>

                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Madlords;