import React from 'react';

const ContactContent = () => {
    return (
        <div className='contact-content'>
            <div className='leftInfo'>
            <img src='./ressources/images/compressjpeg/000042.jpg' alt="test"/>

            </div>
            <div className='rightInfo'>
                <h1>Lyon</h1>
                <p>Ballad Club</p>
                <p>5 rue Vauban</p>
                <p>69006</p>
                <br/>
                <h2>Directrice de Production</h2>
                <p>Marie Vivière</p>
                <p>marie@abruptprod.com</p>
                <p>06 20 27 28 20</p>
                <br/>
                <h2>Réalisateur</h2>
                <p>Alexandre Meunier</p>
                <p>alexandre@abruptprod.com</p>

                <p>06 81 25 49 85</p>
                <br/>
                <p>candidatures et stages</p>
                <p>marie@abruptprod.com</p>
            </div>
        </div>
    );
};

export default ContactContent;