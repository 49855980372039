import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Opco = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'>
                    <ReactPlayer url="https://vimeo.com/793356344/439c503e74" controls/> 
                    <ReactPlayer url="https://vimeo.com/793304255/9033975e2b" controls/>
                </div>
                <div className='credits'>
                    <h1>Opco mobilités</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Agency</span> Studio Drive<br/>
                    <span>Producer</span> Marie Vivière <br/>
                    <span>Director</span> Alexandre Meunier<br/>
                    <span>Electrician</span> Alexandre Thimonier<br/>
                    <span>Photographer</span> Jean Baptiste Gautier<br/>

                    </p>
                </div>
            </div>
        </div>
    );
};

export default Opco;