import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Rove = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/778951027" controls/></div>
                <div className='credits'>
                    <h1>Cegid</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Agency</span> Studio Drive<br/>
                    <span>Producer</span> Marie Vivière <br/>
                    <span>Director</span> Alexandre Meunier<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Rove;