import React from 'react';
import { NavLink } from 'react-router-dom';
import {Helmet} from "react-helmet";



const Navigation = () => {
    return (
        <div className="navigation">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Abrupt Prod</title>
                <meta name="description" content="Projets : Écriture, pré production, direction artistique, production, tournage, réalisation, post production." />
                <link rel="canonical" href="https://abruptprod.com/projects" />
            </Helmet>
            <ul>
                <NavLink to="/abrupt" className={(nav) => (nav.isActive ? "nav-active":"")}>   
                    <img className="logoAbrupt" src="/ressources/images/ABT_LOGO_VECTOR.svg"/>
                </NavLink>
                <NavLink to="/projects" className={(nav) => (nav.isActive ? "nav-active":"")}>
                    <li>PROJETS</li>
                </NavLink>
                <NavLink to="/photos" className={(nav) => (nav.isActive ? "nav-active":"")}>
                    <li>PHOTOS</li>
                </NavLink>
                <NavLink to="/contact" className={(nav) => (nav.isActive ? "nav-active":"")}>
                    <li>CONTACT</li>
                </NavLink>
            </ul>
        </div>
    );
};

export default Navigation;