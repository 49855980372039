import React from 'react';
import Navigation from '../components/Navigation';
import PhotosContent from '../components/PhotosContent';
import {Helmet} from "react-helmet";


const Photos = () => {
    return (
        <div className='photos'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Photos</title>
                <meta name="description" content="Découvrez toutes nos photos !" />
                <link rel="canonical" href="https://abruptprod.com/photos" />
            </Helmet>
            <Navigation/>
            <PhotosContent/>
        </div>
    );
};

export default Photos;