import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Educastream = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/509835041" controls/></div>
                <div className='credits'>
                    <h1>Educastream</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Producer</span> Marie Vivière<br/>
                    <span>Director</span> Aurélien Baud<br/>
                    <span>1st AD</span> Alexandre Meunier<br/>
                    <span>DOP</span> David Montcher<br/>
                    <span>Sound designer</span> Harold Hennequin<br/>
                    <span>Electricians</span> Thomas Leroudier - Juliette Maignan<br/>
                    <span>Location manager</span> Thomas Pernuit<br/>
                    <span>Stylist</span> Julie Simoneau<br/>
                    <span>MUAH</span> Laurette Darnaut - Alexia Boizet<br/>
                    <span>Cast</span><br/>
                    Michel Biel<br/>
                    Daphné Lacouture<br/>
                    Morgan brosed Ponce<br/>
                    Khaled Rahmouni<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Educastream;