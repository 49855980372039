import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Grama = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/775859201" controls/></div>
                <div className='credits'>
                    <h1>Grama</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Director by</span> Alexandre Meunier<br/>
                    <span>Art direction</span> Marie Vivière <br/>
                    <span>DOP</span> Aurélien Baud<br/>
                    <span>Stylist</span> Julie Simoneau <br/>
                    <span>Electrician</span> Alexandre Thimonier<br/>
                    <span>Make-up artist</span> Laurette Darnault <br/>
                    <span>Editor & color grading</span> Alexandre Meunier <br/>
                    <span>Photographer</span> Jean Baptiste Gautier<br/><br/>

                    <span>Cast</span> Oscar Emma Jade
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Grama;