import React, { useState, useEffect } from 'react';

const initialImagesData = [
    {
        column: "firstColumn",
        images: [
            { src: "/ressources/images/photod/000019.jpg", type: "large" },
            { src: "/ressources/images/photod/000020.jpg", type: "large" },
            { src: "/ressources/images/photod/000029.jpg", type: "thin" },
            { src: "/ressources/images/photod/000018.jpg", type: "thin" },
            { src: "/ressources/images/photod/000015.jpg", type: "large" },
            { src: "/ressources/images/photod/000001.jpg", type: "thin"},
            { src: "/ressources/images/photod/000009.jpg", type: "thin" },
            //{ src: "/ressources/images/photod/Photo35_2A.jpg", type: "large" },
        ]
    },
    {
        column: "secondColumn",
        images: [
            { src: "/ressources/images/photod/000031.jpg", type: "thin" },
            { src: "/ressources/images/photod/000034.jpg", type: "large" },
            { src: "/ressources/images/photod/000035.jpg", type: "thin" },
            { src: "/ressources/images/photod/000007-2.jpg", type: "large" },
            { src: "/ressources/images/photod/000009-2.jpg", type: "thin" },
            { src: "/ressources/images/photod/000010.jpg", type: "thin" },
            { src: "/ressources/images/photod/000007.jpg", type: "large" }
        ]
    },
    {
        column: "secondColumn",
        images: [
            { src: "/ressources/images/photod/000041.jpg", type: "large" },
            { src: "/ressources/images/photod/000042.jpg", type: "thin" },
            { src: "/ressources/images/photod/000021.jpg", type: "large" },
            { src: "/ressources/images/photod/000025.jpg", type: "large" },
            { src: "/ressources/images/photod/DSCF3496.jpg", type: "thin" },
            { src: "/ressources/images/photod/000039.jpg", type: "large" },
            { src: "/ressources/images/photod/Photo03_34A.jpg", type: "thin" },
            { src: "/ressources/images/photod/Photo09_28A.jpg", type: "large" }
        ]
    }
];

const shufflePhotos = (columns) => {
    const allPhotos = columns.flatMap(column => column.images);
    const shuffledPhotos = allPhotos.sort(() => Math.random() - 0.5);

    // Répartir les photos mélangées dans les colonnes tout en respectant leur type
    return columns.map(column => {
        const columnPhotos = column.images.map(photo => {
            // Trouve une photo du même type
            const index = shuffledPhotos.findIndex(p => p.type === photo.type);
            const selectedPhoto = shuffledPhotos[index];
            // Supprime la photo sélectionnée pour éviter les doublons
            shuffledPhotos.splice(index, 1);
            return selectedPhoto;
        });
        return { ...column, images: columnPhotos };
    });
};

const PhotosContent = () => {
    const [imagesData, setImagesData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const shuffledData = shufflePhotos(initialImagesData);
        setImagesData(shuffledData);
        setIsLoaded(true); // Afficher les images après mélange
    }, []);

    const shufflePhotos = (columns) => {
        const allPhotos = columns.flatMap(column => column.images);
        const shuffledPhotos = allPhotos.sort(() => Math.random() - 0.5);

        return columns.map(column => {
            const columnPhotos = column.images.map(photo => {
                const index = shuffledPhotos.findIndex(p => p.type === photo.type);
                const selectedPhoto = shuffledPhotos[index];
                shuffledPhotos.splice(index, 1);
                return selectedPhoto;
            });
            return { ...column, images: columnPhotos };
        });
    };

    return (
        <div className="photosContent">
            {isLoaded
                ? imagesData.map((columnData, index) => (
                      <div key={index} className={`column ${columnData.column}`}>
                          {columnData.images.map((image, imgIndex) => (
                              <img
                                  key={imgIndex}
                                  src={image.src}
                                  alt="test"
                                  className={image.type === "large" ? "largePhoto" : "thinPhoto"}
                              />
                          ))}
                      </div>
                  ))
                : null // Ne rien afficher tant que les images ne sont pas prêtes
            }
        </div>
    );
};

// const PhotosContent = () => {
//     return (
//         <div className='photosContent'>
//             <div className='column firstColumn'>
//                 <img src='/ressources/images/Photo13_24A.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo35_2A.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo11_26A.jpg' alt="test"/>
//                 <img src='/ressources/images/compressjpeg/DSC04318.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo32_5A.jpg' alt="test"/>
//                 <img src='/ressources/images/compressjpeg/000029.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000031.jpg' alt="test" />
//                 {/* <img src='./ressources/images/compressjpeg/000009.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000017.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000009-2.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/DSCF3496.jpg' alt="test"/> */}
//             </div>
//             <div className='column secondColumn'>
//                 <img src='./ressources/images/compressjpeg/DSC04616.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo29_8A.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo03_34A.jpg' alt="test"/>
//                 <img src='/ressources/images/compressjpeg/000021.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo12_25A.jpg' alt="test"/>
//                 {/* <img src='./ressources/images/compressjpeg/000039.jpg' alt="test"/> */}
//                 <img src='./ressources/images/compressjpeg/000010.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000042.jpg' alt="test"/>
//                 {/* <img src='./ressources/images/compressjpeg/000015.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000011.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/_DSC3782.jpg' alt="test"/> */}
//             </div>
//             <div className='column thirdColumn'>
//                 <img src='/ressources/images/compressjpeg/DSC04256.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo25_12A.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/DSC04656.jpg' alt="test"/>
//                 <img src='/ressources/images/Photo33_4A.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000033.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000014.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/DSC04605.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/000018.jpg' alt="test"/>
//                 {/* <img src='./ressources/images/compressjpeg/_DSC3761.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/Photo01_36.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/DSCF3467.jpg' alt="test"/>
//                 <img src='./ressources/images/compressjpeg/_DSC3734.jpg' alt="test"/> */}
//             </div>
//         </div>
//     );
// };

export default PhotosContent;