import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Renault = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/919980460" controls/></div>
                <div className='credits'>
                    <h1>Renault Trucks</h1>
                    <br/>
                    <p>
                        <span>Agence</span> Insign <br/>
                        <span>Production</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>Réalisateur</span> Loïc Foulon<br/>
                        <span>Chef opérateur</span> Jean Combier <br/>
                        <span>1er assistant caméra</span> Alexandre Thimonier<br/>
                        <span>Chef électricien</span> Charles Sayer<br/>
                        <span>Electricien</span> Théo Charpentier - Quentin Cardot<br/>
                        <span>Chef machiniste</span> Tristan Besson<br/>
                        <span>Machiniste</span> Salomé Queyraud <br/>
                        <span>Comédien.ne.s</span> Maeva Lasserre - Christophe Martin<br/>
                        <span>Monteur & FX</span> Loïc Foulon <br/>
                        <span>3D</span> Pump studio<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Renault;