import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Winter = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/1036675174" controls/></div>
                <div className='credits'>
                    <h1>Get your grama</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Director</span> Marie Vivière - Alexandre Meunier <br/>
                    <span>DOP</span> Alexandre Meunier <br/>
                    <span>Ass cam / Electrician</span> Alexandre Thimonier <br/>
                    <span>Stylist</span> Julie Simoneau <br/>
                    <span>MUAH</span> Laurette Darnaut <br/>
                    <span>Color Grading</span> Alexandre Meunier <br/>
                    <span>Cast</span> Anissa - Clara - Mathis - Pierre Philippe 
                    </p>
                </div>
            </div>

            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/775859201" controls/></div>
                <div className='credits'>
                    <h1>Grama 23</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Director by</span> Alexandre Meunier<br/>
                    <span>Art direction</span> Marie Vivière <br/>
                    <span>DOP</span> Aurélien Baud<br/>
                    <span>Stylist</span> Julie Simoneau <br/>
                    <span>Electrician</span> Alexandre Thimonier<br/>
                    <span>Make-up artist</span> Laurette Darnault <br/>
                    <span>Editor & color grading</span> Alexandre Meunier <br/>
                    <span>Photographer</span> Jean Baptiste Gautier<br/><br/>

                    <span>Cast</span> Oscar Emma Jade
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Winter;