import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {useMedia} from 'react-use';


const ProjectContent = () => {
    const [pHovered, setPHovered] = useState(false); 
    const [displayImage, setDisplayImage] = useState(0);
    const isMobile = useMedia('(max-width:600px)');
    const [isMotion, setIsMotion] = useState(true);
    return (
        <div className='container'>
                <div className='projectContent'>
                    {/* <img src={'./ressources/images/vignettes/table.jpg'} alt='rien' style={!isMobile ? displayImage === 31 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    <img src={'./ressources/images/vignettes/alinea.png'} alt='rien' style={!isMobile ? displayImage === 2 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/vkm.png'} alt='rien' style={!isMobile ? displayImage === 32 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/RENAUD.png'} alt='rien' style={!isMobile ? displayImage === 30 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/educastreamredi.png'} alt='rien' style={!isMobile ? displayImage === 1 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/paris.png'} alt='rien' style={!isMobile ? displayImage === 3 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/38.jpeg'} alt='rien' style={!isMobile ? displayImage === 4 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/FREEDOM13.jpg'} alt='rien' style={!isMobile ? displayImage === 5 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    {/* <img src={'./ressources/images/vignettes/printemps.png'} alt='rien' style={!isMobile ? displayImage === 6 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    <img src={'./ressources/images/vignettes/01redi.jpg'} alt='rien' style={!isMobile ? displayImage === 7 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    {/* <img src={'./ressources/images/vignettes/lavieclaire2.jpg'} alt='rien' style={!isMobile ? displayImage === 8 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    {/* <img src={'./ressources/images/vignettes/Cappadoce.png'} alt='rien' style={!isMobile ? displayImage === 9 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    {/* <img src={'./ressources/images/vignettes/vkm.png'} alt='rien' style={!isMobile ? displayImage === 10 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    <img src={'./ressources/images/vignettes/cite.png'} alt='rien' style={!isMobile ? displayImage === 11 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/Mon-Element_31.png'} alt='rien' style={!isMobile ? displayImage === 12 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettes/opco.png'} alt='rien' style={!isMobile ? displayImage === 13 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    {/* <img src={'./ressources/images/vignettes/digoin.png'} alt='rien' style={!isMobile ? displayImage === 14 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    <img src={'./ressources/images/vignettes/madlords.jpg'} alt='rien' style={!isMobile ? displayImage === 15 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>
                    {/* <img src={'./ressources/images/vignettes/souslegui.png'} alt='rien' style={!isMobile ? displayImage === 16 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    {/* <img src={'./ressources/images/vignettes/mbmilano.png'} alt='rien' style={!isMobile ? displayImage === 17 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/> */}
                    <img src={'./ressources/images/vignettes/taqt.png'} alt='rien' style={!isMobile ? displayImage === 18 ? {opacity: 1} : {opacity: 0} : {display: 'none'}}/>

                    <img src={'./ressources/images/vignettesmotion/EDUCASTREAM.png'} alt='rien' style={!isMobile ? displayImage === 19 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/CPF.png'} alt='rien' style={!isMobile ? displayImage === 20 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/FEDEREC.png'} alt='rien' style={!isMobile ? displayImage === 21 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/EMLYON.png'} alt='rien' style={!isMobile ? displayImage === 22 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/IFINANCECOURTAGE.png'} alt='rien' style={!isMobile ? displayImage === 23 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/SNCFTER.png'} alt='rien' style={!isMobile ? displayImage === 24 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/PROFEEL.png'} alt='rien' style={!isMobile ? displayImage === 25 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/EUREX.jpg'} alt='rien' style={!isMobile ? displayImage === 26 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/IFINANCE.jpg'} alt='rien' style={!isMobile ? displayImage === 27 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/MANOUVELLEASSURANCE.png'} alt='rien' style={!isMobile ? displayImage === 28 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <img src={'./ressources/images/vignettesmotion/FEEBAT.png'} alt='rien' style={!isMobile ? displayImage === 29 ? {opacity: 0.8} : {opacity: 0} : {display: 'none'}}/>
                    <div className='films' style={!isMotion ? {display: 'none'} : {}}>
                            <div className='text' style={isMobile ? {display: 'none'} : {}}>
                                <p onMouseOver={()=>{setPHovered(true);}} onMouseOut={()=>{setPHovered(false);}}>
                                    {/* <span onMouseOver={()=>{setDisplayImage(31);}} onMouseOut={()=>{setDisplayImage(0);}} ><NavLink to="/projects/automne" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>alinéa automne </NavLink></span>-  */}
                                    <span onMouseOver={()=>{setDisplayImage(2);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/alinea" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>alinéa </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(30);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/renault" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>renault trucks </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(32);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/winter" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>grama </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(1);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/educastream" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>educastream </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(3);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/paris1985" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>Paris 1985 </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(4);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/seeyouagain" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>see you again </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(5);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/freedom" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>freedom  </NavLink></span> - 
                                    {/* <span onMouseOver={()=>{setDisplayImage(6);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/printemps" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>alinéa printemps </NavLink></span>-  */}
                                    <span onMouseOver={()=>{setDisplayImage(7);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/longlivetheking" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>long live the king </NavLink></span>- 
                                    {/* <span onMouseOver={()=>{setDisplayImage(8);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/lavieclaire" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>la vie claire  </NavLink></span>-  */}
                                    {/* <span onMouseOver={()=>{setDisplayImage(9);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/cappadoce" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>cappadoce </NavLink></span>-  */}
                                    {/* <span onMouseOver={()=>{setLink('./ressources/images/vignettes/cegid.png');}} onMouseOut={()=>{setLink('./ressources/images/noir.png');}} > <NavLink to="/projects/rove" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>cegid  </NavLink></span>-  */}
                                    {/* <span onMouseOver={()=>{setDisplayImage(10);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/grama" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>grama  </NavLink></span>-  */}
                                    <span onMouseOver={()=>{setDisplayImage(11);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/cite" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>bouygues immobilier </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(12);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/vongoc" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>Von Goc </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(13);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/opco" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>opco mobilités </NavLink></span>- 
                                    {/* <span onMouseOver={()=>{setDisplayImage(14);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/digoin" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>manufacture digoin </NavLink></span>-  */}
                                    <span onMouseOver={()=>{setDisplayImage(15);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/madlords" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>mad lords </NavLink></span>- 
                                    {/* <span onMouseOver={()=>{setDisplayImage(16);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/souslegui" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>sous le gui </NavLink></span>-  */}
                                    {/* <span onMouseOver={()=>{setDisplayImage(17);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/mbmilano" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>mb milano </NavLink></span>-  */}
                                    <span onMouseOver={()=>{setDisplayImage(18);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/taqt" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>taqt x crédit agricole</NavLink></span>
                                    {/* <span onMouseOver={()=>{setLink('./ressources/images/000015.jpg');}} onMouseOut={()=>{setLink('./ressources/images/noir.png');}} > <NavLink to="/projects/credit" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>crédit agricole</NavLink></span>  */}
                                </p>
                            </div>
                            <div className='text2' style={isMobile ? {} : {display: 'none'}}>
                                <p>
                                {/* <span><NavLink to="/projects/automne" style={{ textDecoration: 'none' }}>alinéa automne </NavLink></span><br/> */}
                                <span><NavLink to="/projects/alinea" style={{ textDecoration: 'none' }} >alinéa </NavLink></span><br/>
                                <span><NavLink to="/projects/renault" style={{ textDecoration: 'none' }}>renault trucks </NavLink></span><br/>
                                <span><NavLink to="/projects/winter" style={{ textDecoration: 'none' }}>grama </NavLink></span><br/>
                                <span><NavLink to="/projects/educastream" style={{ textDecoration: 'none' }}>educastream </NavLink></span><br/>
                                <span><NavLink to="/projects/paris1985" style={{ textDecoration: 'none' }} >Paris 1985 </NavLink></span><br/> 
                                <span><NavLink to="/projects/seeyouagain" style={{ textDecoration: 'none' }} >see you again </NavLink></span><br/>
                                <span><NavLink to="/projects/freedom" style={{ textDecoration: 'none' }} >freedom  </NavLink></span><br/>
                                {/* <span><NavLink to="/projects/printemps" style={{ textDecoration: 'none' }} >alinéa printemps </NavLink></span><br/> */}
                                <span><NavLink to="/projects/longlivetheking" style={{ textDecoration: 'none' }} >long live the king </NavLink></span><br/> 
                                {/* <span><NavLink to="/projects/lavieclaire" style={{ textDecoration: 'none' }} >la vie claire  </NavLink></span><br/>  */}
                                {/* <span><NavLink to="/projects/cappadoce" style={{ textDecoration: 'none' }} >cappadoce </NavLink></span><br/>  */}
                                {/* <;}} > <NavLink to="/projects/rove" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>cegid  </NavLink></span>-  */}
                                {/* <span><NavLink to="/projects/grama" style={{ textDecoration: 'none' }} >grama </NavLink></span><br/>  */}
                                <span><NavLink to="/projects/cite" style={{ textDecoration: 'none' }} >bouygues immobilier </NavLink></span><br/> 
                                <span><NavLink to="/projects/vongoc" style={{ textDecoration: 'none' }} >Von Goc </NavLink></span><br/> 
                                <span><NavLink to="/projects/opco" style={{ textDecoration: 'none' }} >opco mobilités </NavLink></span><br/> 
                                {/* <span><NavLink to="/projects/digoin" style={{ textDecoration: 'none' }}>manufacture digoin </NavLink></span><br/>  */}
                                <span><NavLink to="/projects/madlords" style={{ textDecoration: 'none' }} >mad lords </NavLink></span><br/> 
                                {/* <span><NavLink to="/projects/souslegui" style={{ textDecoration: 'none' }} >sous le gui </NavLink></span><br/>  */}
                                {/* <span><NavLink to="/projects/mbmilano" style={{ textDecoration: 'none' }} >mb milano </NavLink></span><br/>  */}
                                <span><NavLink to="/projects/taqt" style={{ textDecoration: 'none' }} >taqt x crédit agricole</NavLink></span>
                                </p>
                            </div>
                    </div>
                    <div className='motion' style={isMotion ? {display: 'none'} : {}}>
                            <div className='text' style={isMobile ? {display: 'none'} : {}}>
                                <p onMouseOver={()=>{setPHovered(true);}} onMouseOut={()=>{setPHovered(false);}}>
                                    <span onMouseOver={()=>{setDisplayImage(19);}} onMouseOut={()=>{setDisplayImage(0);}} ><NavLink to="/projects/motion/educastream" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>educastream </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(20);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/cpf" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>cpf </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(21);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/federec" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>federec </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(22);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/emlyon" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>em lyon embao </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(23);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/ifinancecourtage" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>ifinance courtage </NavLink></span> - 
                                    <span onMouseOver={()=>{setDisplayImage(24);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/sncf" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>sncf ter </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(25);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/profeel" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>profeel </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(26);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/eurex" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>eurex  </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(27);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/ifinaance" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>ifinance </NavLink></span>- 
                                    {/* <span onMouseOver={()=>{setLink('./ressources/images/vignettes/cegid.png');}} onMouseOut={()=>{setLink('./ressources/images/noir.png');}} > <NavLink to="/projects/rove" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>cegid  </NavLink></span>-  */}
                                    <span onMouseOver={()=>{setDisplayImage(28);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/manouvelleassurance" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>ma nouvelle assurance  </NavLink></span>- 
                                    <span onMouseOver={()=>{setDisplayImage(29);}} onMouseOut={()=>{setDisplayImage(0);}} > <NavLink to="/projects/motion/feebat" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>feebat </NavLink></span>
                                </p>
                            </div>
                            <div className='text2' style={isMobile ? {} : {display: 'none'}}>
                                <p>
                                <span><NavLink to="/projects/motion/educastream" style={{ textDecoration: 'none' }}>educastream </NavLink></span><br/>
                                <span><NavLink to="/projects/motion/cpf" style={{ textDecoration: 'none' }} >cpf </NavLink></span><br/>
                                <span><NavLink to="/projects/motion/federec" style={{ textDecoration: 'none' }} >federec </NavLink></span><br/> 
                                <span><NavLink to="/projects/motion/emlyon" style={{ textDecoration: 'none' }} >em lyon embao </NavLink></span><br/>
                                <span><NavLink to="/projects/motion/ifinancecourtage" style={{ textDecoration: 'none' }} >ifinance courtage  </NavLink></span><br/>
                                <span><NavLink to="/projects/motion/sncf" style={{ textDecoration: 'none' }} >sncf ter </NavLink></span><br/>
                                <span><NavLink to="/projects/motion/profeel" style={{ textDecoration: 'none' }} >profeel </NavLink></span><br/> 
                                <span><NavLink to="/projects/motion/eurex" style={{ textDecoration: 'none' }} >eurex  </NavLink></span><br/> 
                                <span><NavLink to="/projects/motion/ifinance" style={{ textDecoration: 'none' }} >ifinance </NavLink></span><br/> 
                                {/* <;}} > <NavLink to="/projects/rove" style={{ textDecoration: 'none' }} className={pHovered ? "navlink" : ""}>cegid  </NavLink></span>-  */}
                                <span><NavLink to="/projects/motion/manouvelleassurance" style={{ textDecoration: 'none' }} >ma nouvelle assurance</NavLink></span><br/> 
                                <span><NavLink to="/projects/motion/feebat" style={{ textDecoration: 'none' }} >feebat </NavLink></span><br/> 
                                </p>
                            </div>
                    </div>
            </div>

            <div className='choice'><span className={isMotion ? "isMotion" : ""} onClick={()=>setIsMotion(true)}>films</span> - <span className={!isMotion ? "isMotion" : ""} onClick={()=>setIsMotion(false)}>motion</span></div>
        </div>
    );
};

export default ProjectContent;