import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const VonGoc = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/778951027" controls/></div>
                <div className='credits'>
                    <h1>Von Goc</h1>
                    <br/>
                    <p>
                    <span>Starring</span> Ayumi Roux and Von Goc <br/>
                    <span>Directed by</span> Loïc Foulon <br/>
                    <span>Production</span> Abrupt <br/>
                    <span>Producer</span> Marie Vivière <br/>
                    <span>Assistant producer</span> Hugo Gatefait <br/>
                    <span>Cinematographer</span> Jean Combier <br/>
                    <span>Assistant director</span> Alexandre Meunier <br/>
                    <span>Stylists</span> Sarah Ashford-Brown / Julie Simoneau <br/>
                    <span>Electricians</span> Charles Sayer / Théo Charpentier <br/>
                    <span>Make-up artist</span> Laurette Darnault <br/>
                    <span>Location managers</span> Khaled Rahmouni / Morgan Brosed Ponce <br/>
                    <span>Color grading</span> Jean Combier <br/>
                    <span>Editor/VFX</span> Loïc Foulon <br/>
                    <span>Still photographer</span> Laura Lauret-Milazzo <br/>
                    <span>Music mix and mastering</span> Johan Putet <br/>
                    <span>Sound design</span> Loïc Foulon <br/>
                    <span>Sound mix</span> Guillaume Panloup <br/>
                    <span>Equipment rental</span> Indie Location / Pix Location <br/><br/>
                    <span>Special Thanks</span> <br/>
                    6ème Sens Immobilier <br/>
                    Emmanuel Mazille<br/>
                    Carmen Norstrom Shot with BMPCC4K
                    </p>
                </div>
            </div>
        </div>
    );
};

export default VonGoc;