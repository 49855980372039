import React from 'react';
import Navigation from '../components/Navigation';
import ProjectContent from '../components/ProjectContent';
import {Helmet} from "react-helmet";


const Projects = () => {
    return (
        <div className='projects'>
            <Navigation/>
            <ProjectContent/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Projets</title>
                <meta name="description" content="Projets : Écriture, pré production, direction artistique, production, tournage, réalisation, post production." />
                <link rel="canonical" href="https://abruptprod.com/projects" />
            </Helmet>
        </div>
    );
};

export default Projects;