import React from 'react';
import Navigation from '../components/Navigation';
import ContactContent from '../components/ContactContent';
import {Helmet} from "react-helmet";


const Contact = () => {
    return (
        <div className='contact'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact</title>
                <meta name="description" content="Parlez-nous de vos projets !" />
                <link rel="canonical" href="https://abruptprod.com/contact" />
            </Helmet>
            <Navigation/>
            <ContactContent />
        </div>
    );
};

export default Contact;