import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Freedom = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/542136188" controls/></div>
                <div className='credits'>
                    <h1>Freedom</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt<br/>
                    <span>Director</span> Marie Vivière<br/>
                    <span>DOP</span> Alexandre Meunier<br/>
                    <span>Gaffer</span> Alexandre Thimonier<br/>
                    <span>Drone operator</span> Aurélien Baud <br/>
                    <span>Stylist</span> Zérokatre<br/>
                    <span>Costum design</span> Sarah Bounab <br/>
                    <span>Make up artist & hair</span> Noémie Revial<br/>
                    <span>Electrician</span> Louis grochowicki <br/>
                    <span>Color grading</span> Jean Combier<br/>
                    <span>Dancer</span> Maeva Lassere <br/> <br/>
                    <span>Casting</span> <br/>
                    Jade Gossery Kim Gautheron <br/>
                    Garance Ailloud Flora Soule <br/>
                    Marie Compagnon
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Freedom;