import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Seeyouagain = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/779316933" controls/></div>
                <div className='credits'>
                    <h1>See you again</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt<br/>
                    <span>Artist</span> Elle Valenci<br/>
                    <span>Producer</span> Alexandre Meunier<br/>
                    <span>Director</span> Aurélien Baud<br/>
                    <span>Creative direction</span> Marie Vivière<br/>
                    <span>Stylist</span> Zérokatre<br/>
                    <span>Make up artist & hair</span> Laurette Darnaud <br/>

                    </p>
                </div>
            </div>
        </div>
    );
};

export default Seeyouagain;