import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Credit = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="./ressources/videos/alineaxcappadoce.mp4" controls/></div>
                <div className='credits'>
                    <h1>Alinéa x Cappadoce</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Loic Foulon<br/>
                        <span>Ass. director</span> Alexandre Meunier<br/>
                        <span>Producers</span> Marie Vivière <br/>
                        <span>DOP</span> Jean Combier<br/>
                        <span>Ass cam</span> Maxime Kieken<br/>
                        <span>Electrician</span> Charles Sayer<br/>
                        <span>Color</span> Grading Jean Combier<br/>
                        <span>Music mix and mastering</span> Antoine Duchene <br/>
                        <span>Voice Over</span> Seth Young Ruiz<br/>
                        <span>Equipment rental</span> Indie Location / Pix Location <br/>
                        <span>Cast</span> Cédric Bieler<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Credit;