import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Digoin = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'>
                    <ReactPlayer url="https://vimeo.com/778910110" controls/>
                    
                </div>
                <div className='credits'>
                    <h1>Manufacture Digoin</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Aurélien Baud
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Digoin;