import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Cappadoce = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/776302403" controls/></div>
                <div className='credits'>
                    <h1>Cappadoce</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Aurélien baud<br/>
                        <span>Producers</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> Julien Jauffret<br/>
                        <span>Electrician</span> Charlène Dumons<br/>
                        <span>Make-up artist</span> Laurette Darnaud<br/>
                        <span>Editor & Color</span> Grading Aurélien Baud<br/>
                        <span>Music mix and mastering</span> Antoine Duchene <br/>
                        <span>BTS</span> Robin Durget<br/><br/>


                        <span>Cast</span> Elodie Senigallia - Maxence Victor
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Cappadoce;